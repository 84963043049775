import { Injectable } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, Store, ofAction } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { AdminPagesStateDefaults, SetError } from "src/app/features/admin/admin.state";
import { UsersState } from "src/app/features/users/state/users.state";
import { FilterAction } from "src/app/shared/components/filters/filters.component";
import { SetFilter } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { PAG_MAX_RESULTS, getFilterData, getUpdatedItems, mapTimesheet, mapTimesheetRow, mapTotalHoursTimesheet } from "src/app/shared/utils/utils";
import { TimeSheet } from "../../../../core/interfaces/timesheet.interface";
import { TimeSheetRow } from "../../interfaces/timesheet-row.model";
import { TotalHoursValue } from "../../interfaces/total-hours-values.model";
import { TimesheetsService } from "../../services/timesheets.service";
import {
  AddTimeSheet,
  AdjustTimeSheet,
  AssociateTimesheetToOrder,
  CheckTimeSheetError,
  ClearErrorUpdating,
  ClearRowAdded,
  ClearTimeSheet,
  CreateTimesheetRow,
  DuplicateTimeSheet,
  GetLaborOrderWithTimeSheets,
  GetTimeSheetById,
  GetTimesheetDetails,
  LoadTimeSheets,
  LoadTimeSheetsGroupedByLaborOrder,
  LoadTimekeepers,
  OfferAssociateTimesheetToOrder,
  RecoverTimeSheet,
  RemoveTimeSheet,
  RemoveTimeSheetRow,
  ResetFilterByOrder,
  ResetTimeSheetRows,
  ResetTimeheetsState,
  RowAddedOrRemoved,
  SetLaborOrderTimesheets,
  SetTimeSheetListToStatusApproved,
  SetTimeSheetListToStatusPendingApproval,
  SetTimeSheetListToStatusUnderReview,
  SetTimeSheetRowStatusDraft,
  SetTimeSheetRowStatusRejected,
  SetTimeSheetStatusApproved,
  SetTimeSheetStatusPendingApproval,
  SetTimeSheetStatusPendingReapproval,
  SetTimeSheetStatusRejected,
  SetTimeSheetStatusUnderReview,
  UpdateCurrentGang,
  UpdateFilterByOrder,
  UpdateFilterByTimesheet,
  UpdateTimeSheet,
  UpdateTimesheetRows
} from "./timesheets.actions";
import { TimeheetsStateModel } from "./timesheets.model";

export const defaultState = {
  ...AdminPagesStateDefaults,
  removing: false,
  updating: null,
  adding: false,
  rowAdded: false,
  timesheetRows: [],
  errorUpdating: null,
  errorLoading: false,
  calculatedTotalAmount: null,
  hourRateTotals: null,
  totalTimesheets: null,
  itemsByLaborOrder: [],
  filterByOrder: null,
  filterByTimesheet: null,
  currentGang: '',
  timeKeepers: []
};

@State<TimeheetsStateModel>({
  name: "timesheets",
  defaults: defaultState
})
@Injectable()
export class TimeheetsState {
  constructor(
    private TimesheetsService: TimesheetsService,
    private actions$: Actions,
    private store: Store
  ) {}
  isFiltered: false;

  @Selector() static timeSheets(state: TimeheetsStateModel) { return state.items; }
  @Selector() static loading(state: TimeheetsStateModel) { return state.loading; }
  @Selector() static rowAdded(state: TimeheetsStateModel) { return state.rowAdded; }
  @Selector() static updating(state: TimeheetsStateModel) { return state.updating; }
  @Selector() static errorUpdating(state: TimeheetsStateModel) { return state.errorUpdating; }
  @Selector() static errorLoading(state: TimeheetsStateModel) { return state.errorLoading; }
  @Selector() static loaded(state: TimeheetsStateModel) { return state.loaded; }
  @Selector() static saving(state: TimeheetsStateModel) { return state.saving; }
  @Selector() static selectedItem(state: TimeheetsStateModel) { return state.selectedItem; }
  @Selector() static mappedTimesheet(state: TimeheetsStateModel) { return state.mappedTimesheet; }
  @Selector() static timesheetRows(state: TimeheetsStateModel) { return state.timesheetRows; }
  @Selector() static calculatedTotalAmount(state: TimeheetsStateModel) { return state.calculatedTotalAmount; }
  @Selector() static hourRateTotals(state: TimeheetsStateModel) { return state.hourRateTotals; }
  @Selector() static itemsByLaborOrder(state: TimeheetsStateModel) { return state.itemsByLaborOrder; }
  @Selector() static selectedLaborOrder(state: TimeheetsStateModel) { return state.selectedLaborOrder; }
  @Selector() static timeSheetRowErros(state: TimeheetsStateModel) { return state.timeSheetRowErros; }
  @Selector() static filterByOrder(state: TimeheetsStateModel) { return state.filterByOrder; }
  @Selector() static filterByTimesheet(state: TimeheetsStateModel) { return state.filterByTimesheet; }
  @Selector() static filteringByDeleted(state: TimeheetsStateModel) { return state.filterByTimesheet?.items?.GetDeleted; }
  @Selector() static currentGang(state: TimeheetsStateModel) { return state.currentGang; }
  @Selector() static timeKeepers(state: TimeheetsStateModel) { return state.timeKeepers; }

  @Action(LoadTimeSheets)
  load(ctx: StateContext<TimeheetsStateModel>, { skipCount, maxResult, filters }) {
    ctx.patchState({
      items: [],
      loading: true
    });

    const employerForTimekeeper = this.store.selectSnapshot(UsersState.employerForTimekeeper);

    if (employerForTimekeeper) {
      if (filters) {
        filters.items = { ...filters.items, EmployerId: employerForTimekeeper };
      } else {
        filters = {
          items: {
            EmployerId: employerForTimekeeper
          }
        };
      }
    }
    return this.TimesheetsService.getTimeSheets(skipCount, maxResult, filters?.items || {}).pipe(
      tap(
        res => {
          this.isFiltered = filters?.hasEnabledFilters;
          ctx.patchState({
            items: res.items,
            loaded: true,
            loading: false,
            totalTimesheets: res.totalCount
          });

          if (!this.isFiltered) {
            const vesselsArray = getFilterData(res, "AvailableVessel");
            ctx.dispatch(new SetFilter("filteredVessels", vesselsArray));

            const timekeepersArray = getFilterData(res, "AvailableTimeKeepers");
            ctx.dispatch(new SetFilter("filteredTimekeepers", timekeepersArray));
          }
        },
        err => {
          ctx.patchState({ loading: false, items: [], totalTimesheets: 0 });
          ctx.dispatch(new SetError({ loading: err }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(AddTimeSheet)
  add(ctx: StateContext<TimeheetsStateModel>, { payload, dialogCallback }) {
    ctx.patchState({ saving: true });
    return this.TimesheetsService.create(payload).subscribe(
      (response) => {
        dialogCallback(true);
        ctx.patchState({ saving: false });
        ctx.dispatch(new OfferAssociateTimesheetToOrder(response.id, null, true));
        ctx.dispatch(new LoadTimeSheets(0, PAG_MAX_RESULTS));
      },
      (error) => {
        ctx.patchState({ saving: false });
        ctx.dispatch(new SetError({ saving: error }));
      }
    );
  }

  @Action(UpdateTimeSheet)
  update(ctx: StateContext<TimeheetsStateModel>, { payload, callback }) {
    ctx.patchState({
      saving: true
    });
    
    return this.TimesheetsService.update(payload).pipe(
      tap(
        (response) => {
          callback();
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            selectedItem: JSON.stringify(response),
            saving: false
          });
        },
        (error) => {
          callback(error);
          ctx.patchState({ saving: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(RemoveTimeSheet)
  remove(ctx: StateContext<TimeheetsStateModel>, { timesheetId, reason, callbackSuccess, callbackError }) {
    return this.TimesheetsService.remove(timesheetId, reason).then(
      (response) => {
        callbackSuccess();
        const state = ctx.getState();
        const updatedTimeSheets = state.items.filter(item => {
          return item.id !== timesheetId;
        });
        ctx.patchState({ items: updatedTimeSheets });
      },
      (error) => {
        callbackError();
        ctx.dispatch(new SetError({ removing: error }));
      }
    );
  }

  @Action(RecoverTimeSheet)
  recoverTimeSheet(ctx: StateContext<TimeheetsStateModel>, action: RecoverTimeSheet) {
    return this.TimesheetsService.recover(action.timesheetId).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          const updatedTimeSheets = state.items.filter(item => {
            return item.id !== action.timesheetId;
          });
          ctx.patchState({ items: updatedTimeSheets });
        },
        (error) => {
          action.callbackError();
          ctx.dispatch(new SetError({ removing: error }));
        }
      )
    );
  }

  @Action(RemoveTimeSheetRow)
  removeRow(ctx: StateContext<TimeheetsStateModel>, action: RemoveTimeSheetRow) {
    ctx.patchState({ removing: true });
    return this.TimesheetsService.removeRow(action.timesheetId, action.rowIds).pipe(
      tap(
        (response) => {
          ctx.dispatch(new RowAddedOrRemoved());
          action.callback(null);
        },
        (error) => {
          action.callback(error);
          ctx.patchState({ removing: false });
        }
      )
    );
  }

  @Action(CreateTimesheetRow)
  createTimesheetRow(ctx: StateContext<TimeheetsStateModel>, { timesheetId, row, callback }) {
    ctx.patchState({ adding: true, rowAdded: false });
    return this.TimesheetsService.updateOrCreateTimeSheetRows(timesheetId, [row]).pipe(
      tap(
        (response) => {
          ctx.dispatch(new RowAddedOrRemoved());
          ctx.patchState({ adding: false, rowAdded: true });
          callback();
        },
        (error) => ctx.patchState({ adding: false })
      )
    );
  }

  queue: Observable<any>[] = [];
  queueRows = [];
  @Action(UpdateTimesheetRows)
  updateOrCreateTimeSheetRows(ctx: StateContext<TimeheetsStateModel>, { timeSheetId, rows, callback }) {
    const request$ = this.TimesheetsService.updateOrCreateTimeSheetRows(timeSheetId, rows);
    let newRows = [];
    let totalAmount;
    let Error: any = null;
    let hourRateTotals: TotalHoursValue;

    //Push items to the queue
    this.queue.push(request$);
    this.queueRows.push(rows);
    // console.log("ADDING 1 REQUEST TO QUEUE: ", this.queue.length + " REMAINING");

    ctx.patchState({ errorUpdating: null });
    const runQueue = () => {
      // console.log("RUNNING QUEUE for ", this.queue.length);
      ctx.patchState({ updating: true });
      if (this.queue.length) {
        return this.queue[0].pipe(
          tap(
            (response) => {
              this.queue.shift();
              // console.log("PUT FINISHED", this.queue.length + " REMAINING", "ROWS: ", response);
              newRows = response.rows;
              totalAmount = response.calculatedTotalAmount;
              hourRateTotals = mapTotalHoursTimesheet(response.hourRateTotals);
              callback();
              runQueue();
            },
            (error) => {
              Error = error;
              this.queue.shift();
              this.queueRows.shift();
              callback(error);
              runQueue();
            }
          )
        ).toPromise();
      } else {
        // Queue has no more items, we process the results
        // console.log("==== end of queue ====");
        if (Error) {
          return ctx.patchState({ errorUpdating: Error, updating: false });
        }

        const state = ctx.getState();
        const currentTimesheet = JSON.parse(state.mappedTimesheet);
        let rowsToBeUpdated = [];

        //This has to be explained...
        // First loop the queue to get the new rows that were added on each iteration for updating
        this.queueRows.forEach(rowsToUpdate => {
          // then get the actual rows for each iteration
          rowsToUpdate.forEach(newRow => {
            //Now we have the newRow wich is the response from the api after the update.
            currentTimesheet.rows.forEach(currentRow => {
              // Get the row to be updated by comparing to the ones sent to the queue
              const rowFromResponse = newRows.find(a => currentRow.id === a.id);

              //don't update the other rows, only the one modified
              if (currentRow.id === newRow.id) {
                newRow.calculatedTotalAmount = rowFromResponse.calculatedTotalAmount;
                newRow.highSTRate = rowFromResponse.highSTRate;
                newRow.highExpRate = rowFromResponse.highExpRate;
                newRow.timeSheetEntries = rowFromResponse.timeSheetEntries;
                newRow.jobCode = rowFromResponse.jobCode;
                newRow.worker = rowFromResponse.worker;
                newRow.rowDifferentialId = rowFromResponse.rowDifferentialId;
                newRow.pi = rowFromResponse.pi;
                newRow.npi = rowFromResponse.npi;
                newRow.timeIn = rowFromResponse.timeIn;
                newRow.timeOut = rowFromResponse.timeOut;
                newRow.trainedJobCode = rowFromResponse.trainedJobCode;
                newRow.entriesTotalHours = rowFromResponse.entriesTotalHours;
                newRow = mapTimesheetRow(newRow);
                rowsToBeUpdated.push(newRow);
              }
            });
          });
        });

        ctx.patchState({ updating: false });
        //Update each row state
        rowsToBeUpdated.forEach(newRow => {
          ctx.setState(
            patch({
              timesheetRows: updateItem<TimeSheetRow>(row => {
                return row.id === newRow.id;
              }, newRow)
            })
          );
        });

        ctx.patchState({ calculatedTotalAmount: totalAmount, hourRateTotals: hourRateTotals });

        //Reset queueRows value and close the queue
        return (this.queueRows = []);
      }
    };
    //Start running loop if the queue has requests
    if (this.queue.length === 1) {
      // console.log("===>> QUEUE START ===== ");
      runQueue();
    }
  }

  @Action(ClearTimeSheet)
  clearTimeSheet(ctx: StateContext<TimeheetsStateModel>) {
    ctx.patchState({ selectedItem: null });
  }

  @Action(GetTimeSheetById)
  getTimeSheetById(ctx: StateContext<TimeheetsStateModel>, action: GetTimeSheetById) {
    ctx.patchState({ loading: true });
  
    return this.TimesheetsService.getTimeSheetById(action.id).pipe(
      tap(
        (response) => {
          ctx.patchState({
            selectedItem: JSON.stringify(response),
            currentGang: response.gang,
            loaded: true,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({
            loading: false, 
            selectedItem: null
          });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(GetTimesheetDetails)
  getTimesheetDetails(ctx: StateContext<TimeheetsStateModel>, action: GetTimesheetDetails) {
    return this.TimesheetsService.getTimeSheetDetails(action.id).pipe(
      tap(
        (response) => {
          const mappedTimesheet = mapTimesheet(response);
          const mappedRows = response.rows.map(row => mapTimesheetRow(row));

          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            calculatedTotalAmount: mappedTimesheet.calculatedTotalAmount,
            hourRateTotals: mappedTimesheet.hourRateTotals,
            timesheetRows: mappedRows,
            loaded: true,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({
            loading: false,
            selectedItem: null,
            errorLoading: true
          });
        }
      )
    );
  }

  @Action(SetTimeSheetStatusApproved)
  setTimeSheetStatusApproved(ctx: StateContext<TimeheetsStateModel>, { timeSheetId, callback }) {
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetApproved(timeSheetId).pipe(
      tap(
        timeSheet => {
          callback();
          const mappedTimesheet = mapTimesheet(timeSheet);
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            selectedItem: JSON.stringify(timeSheet),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetStatusPendingApproval)
  setTimeSheetStatusPendingApproval(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetPendingApproval(timeSheetId).pipe(
      tap(
        timeSheet => {
          callback();
          const mappedTimesheet = mapTimesheet(timeSheet);
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            selectedItem: JSON.stringify(timeSheet),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetStatusPendingReapproval)
  setTimeSheetStatusPendingReapproval(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, reason, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetPendingReapproval(timeSheetId, reason).pipe(
      tap(
        timeSheet => {
          callback();
          const mappedTimesheet = mapTimesheet(timeSheet);
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            selectedItem: JSON.stringify(timeSheet),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetStatusRejected)
  setTimeSheetStatusRejected(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, reason, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetRejected(timeSheetId, reason).pipe(
      tap(
        timeSheet => {
          callback();
          const mappedTimesheet = mapTimesheet(timeSheet);
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            selectedItem: JSON.stringify(timeSheet),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetRowStatusRejected)
  setTimeSheetRowStatusRejected(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, updateModel, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetRowRejected(timeSheetId, updateModel).pipe(
      tap(
        timeSheetRow => {
          callback();
          const mappedTimeSheetRow = mapTimesheetRow(timeSheetRow);
          let mappedTimesheet = JSON.parse(state.mappedTimesheet);
          mappedTimesheet.rows?.map(r => {
            if (r.id === timeSheetRow.id) return mappedTimeSheetRow;
            else return r;
          });
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            timesheetRows: getUpdatedItems(mappedTimeSheetRow, state.timesheetRows),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetRowStatusDraft)
  setTimeSheetRowStatusDraft(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, updateModel, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetRowDraft(timeSheetId, updateModel).pipe(
      tap(
        timeSheetRow => {
          callback();
          let mappedTimesheet = JSON.parse(state.mappedTimesheet);
          mappedTimesheet.rows?.map(r => {
            if (r.id === timeSheetRow.id) return mapTimesheetRow(timeSheetRow);
            else return r;
          });
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            timesheetRows: getUpdatedItems(mapTimesheetRow(timeSheetRow), state.timesheetRows),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetStatusUnderReview)
  setTimeSheetStatusUnderReview(
    ctx: StateContext<TimeheetsStateModel>,
    { timeSheetId, callback }
  ) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetUnderReview(timeSheetId).pipe(
      tap(
        timeSheet => {
          callback();
          const mappedTimesheet = mapTimesheet(timeSheet);
          ctx.patchState({
            mappedTimesheet: JSON.stringify(mappedTimesheet),
            selectedItem: JSON.stringify(timeSheet),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(ResetTimeSheetRows)
  resetTimeSheetRows(ctx: StateContext<TimeheetsStateModel>, {}) {
    ctx.patchState({ timesheetRows: [], mappedTimesheet: null });
  }

  @Action(LoadTimeSheetsGroupedByLaborOrder)
  loadTimeSheetsGroupedByLaborOrder(ctx: StateContext<TimeheetsStateModel>, action: LoadTimeSheetsGroupedByLaborOrder) {
    ctx.patchState({
      items: [],
      loading: true
    });
    
    const employerForTimekeeper = this.store.selectSnapshot(UsersState.employerForTimekeeper);
    if (employerForTimekeeper) {
      if (action.filters) {
        action.filters.items = {
          ...action.filters.items,
          EmployerId: employerForTimekeeper
        };
      } else {
        action.filters = {
          items: {
            EmployerId: employerForTimekeeper
          }
        };
      }
    }

    return this.TimesheetsService.getTimeSheetsGroupedByLaborOrder(action.skipCount, action.maxResult, action.filters?.items || {}).pipe(
      tap(
        res => {
          this.isFiltered = action.filters?.hasEnabledFilters;

          ctx.patchState({
            itemsByLaborOrder: res.items,
            loaded: true,
            loading: false,
            totalTimesheets: res.totalCount
          });
        },
        err => {
          ctx.patchState({ loading: false, itemsByLaborOrder: [], totalTimesheets: 0 });
          ctx.dispatch(new SetError({ loading: err }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(SetLaborOrderTimesheets)
  setLaborOrderTimesheets(ctx: StateContext<TimeheetsStateModel>, { item }) {
    ctx.patchState({ selectedLaborOrder: JSON.stringify(item) });
  }

  @Action(GetLaborOrderWithTimeSheets)
  getLaborOrderWithTimeSheets(ctx: StateContext<TimeheetsStateModel>, { orderId }) {
    ctx.patchState({ loading: true });
    return this.TimesheetsService.getLaborOrderWithTimeSheets(orderId).pipe(
      tap(
        (response) => {
          ctx.patchState({
            selectedLaborOrder: JSON.stringify(response),
            loaded: true,
            loading: false
          });
        },
        (error) => {
          ctx.patchState({ loading: false, selectedLaborOrder: null });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(CheckTimeSheetError)
  checkTimeSheetError(ctx: StateContext<TimeheetsStateModel>, { timeSheetId }) {
    ctx.patchState({ loading: true, timeSheetRowErros: null });
    return this.TimesheetsService.checkTimeSheetError(timeSheetId).pipe(
      tap(
        res => {
          res.forEach(r => {
            r.row = mapTimesheetRow(r.row);
            r.conflictingRow = r.conflictingRow ? mapTimesheetRow(r.conflictingRow) : null;
          });
          ctx.patchState({
            timeSheetRowErros: JSON.stringify(res),
            loaded: true,
            loading: false
          });
        },
        err => {
          ctx.patchState({ loading: false, timeSheetRowErros: null });
          ctx.dispatch(new SetError({ loading: err }));
        }
      )
    );
  }

  @Action(AdjustTimeSheet)
  adjustTimeSheet(ctx: StateContext<TimeheetsStateModel>, { timeSheetId, callback }) {
    ctx.patchState({ loading: true });

    return this.TimesheetsService.adjustTimeSheet(timeSheetId).pipe(
      tap(
        (response) => {
          callback({ timeSheetId: response.id });
          ctx.dispatch(new OfferAssociateTimesheetToOrder(response.id, response.laborOrder, false));
          ctx.patchState({
            selectedItem: JSON.stringify(response),
            loaded: true,
            loading: false
          });
        },
        (err) => {
          callback(err);
          ctx.patchState({ loading: false, selectedItem: null });
          ctx.dispatch(new SetError({ loading: err }));
        }
      )
    );
  }

  @Action(ResetTimeheetsState)
  ResetTimeheetsState(ctx: StateContext<TimeheetsStateModel>, {}) {
    ctx.setState(defaultState);
  }

  @Action(DuplicateTimeSheet)
  duplicateTimeSheet(ctx: StateContext<TimeheetsStateModel>, { timeSheetId, callback }) {
    ctx.patchState({ saving: true });
    return this.TimesheetsService.duplicateTimeSheet(timeSheetId).subscribe(
      (response) => {
        callback({ timeSheetId: response.id });
        ctx.dispatch(new OfferAssociateTimesheetToOrder(response.id, response.laborOrder, false));
        ctx.dispatch(new LoadTimeSheets(0, PAG_MAX_RESULTS));
        ctx.patchState({ saving: false });
      },
      error => {
        ctx.patchState({ saving: false });
        ctx.dispatch(new SetError({ saving: error }));
      }
    );
  }

  @Action(SetTimeSheetListToStatusPendingApproval)
  setTimeSheetListToStatusPendingApproval(ctx: StateContext<TimeheetsStateModel>, { orderId, timeSheetIds, callback }) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetListToPendingApproval(timeSheetIds).pipe(
      tap(
        response => {
          const foundOrder = JSON.parse(
            JSON.stringify(state.itemsByLaborOrder.find(o => o.id === orderId))
          );

          response.timeSheets.forEach(element => {
            const foundTimeSheet = foundOrder.timesheets.find(t => t.id === element.id);
            foundTimeSheet.currentStatus = element.currentStatus;
          });
          callback(!response.message ? null : { message: response.message });
          ctx.patchState({
            itemsByLaborOrder: getUpdatedItems(foundOrder, state.itemsByLaborOrder),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetListToStatusUnderReview)
  setTimeSheetListToStatusUnderReview(ctx: StateContext<TimeheetsStateModel>, { orderId, timeSheetIds, callback }) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetListToUnderReview(timeSheetIds).pipe(
      tap(
        response => {
          const foundOrder = JSON.parse(
            JSON.stringify(state.itemsByLaborOrder.find(o => o.id === orderId))
          );

          response.timeSheets.forEach(element => {
            const foundTimeSheet = foundOrder.timesheets.find(t => t.id === element.id);
            foundTimeSheet.currentStatus = element.currentStatus;
          });
          callback(!response.message ? null : { message: response.message });
          ctx.patchState({
            itemsByLaborOrder: getUpdatedItems(foundOrder, state.itemsByLaborOrder),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(SetTimeSheetListToStatusApproved)
  setTimeSheetListToStatusApproved(ctx: StateContext<TimeheetsStateModel>, { orderId, timeSheetIds, callback }) {
    const state = ctx.getState();
    ctx.patchState({ updating: true });
    return this.TimesheetsService.setTimeSheetListToApproved(timeSheetIds).pipe(
      tap(
        response => {
          const foundOrder = JSON.parse(
            JSON.stringify(state.itemsByLaborOrder.find(o => o.id === orderId))
          );

          response.timeSheets.forEach(element => {
            const foundTimeSheet = foundOrder.timesheets.find(t => t.id === element.id);
            foundTimeSheet.currentStatus = element.currentStatus;
          });
          callback(!response.message ? null : { message: response.message });
          ctx.patchState({
            itemsByLaborOrder: getUpdatedItems(foundOrder, state.itemsByLaborOrder),
            updating: false
          });
        },
        error => {
          callback(error);
          ctx.patchState({ updating: false });
          ctx.dispatch(new SetError({ saving: error }));
        }
      )
    );
  }

  @Action(ClearErrorUpdating)
  clearErrorUpdating(ctx: StateContext<TimeheetsStateModel>) {
    ctx.patchState({ errorUpdating: null });
  }

  @Action(ClearRowAdded)
  clearRowAdded(ctx: StateContext<TimeheetsStateModel>) {
    ctx.patchState({ rowAdded: false });
  }

  @Action(UpdateFilterByOrder)
  updateFilterByOrder(ctx: StateContext<TimeheetsStateModel>, { value }) {
    ctx.patchState({ filterByOrder: value });
  }

  @Action(ResetFilterByOrder)
  resetFilterByOrder(ctx: StateContext<TimeheetsStateModel>) {
    ctx.patchState({ filterByOrder: null });
  }

  @Action(UpdateFilterByTimesheet)
  updateFilterByTimesheet(ctx: StateContext<TimeheetsStateModel>, { value }) {
    ctx.patchState({ filterByTimesheet: value });
  }

  @Action(OfferAssociateTimesheetToOrder)
  offerAssociateTimesheetToOrder(ctx: StateContext<TimeheetsStateModel>, { timesheetId, laborOrder, fromList }) {
    this.TimesheetsService.openModalAssociateTimesheetAndLaborOrder(timesheetId, laborOrder, fromList);
  }
  
  @Action(AssociateTimesheetToOrder)
  associateTimesheetToOrder(ctx: StateContext<TimeheetsStateModel>, { timesheetId, laborOrderId, fromList, callbackSuccess, callbackError }) {
    return this.TimesheetsService.associateTimesheetToLaborOrder(timesheetId, laborOrderId).toPromise().then(
      (response) => {
        const state = ctx.getState();
        callbackSuccess(response);
        if (fromList) {
          let timesheets: Array<TimeSheet> = JSON.parse(JSON.stringify(state.items));
          const timesheetIndex: number = timesheets.findIndex((t) => { return t.id === response.id; });
          if (timesheetIndex) {
            timesheets[timesheetIndex].laborOrder = response.laborOrder;
            ctx.patchState({ items: timesheets });
          }
        } else {
          let timesheet: TimeSheet = JSON.parse(state.selectedItem);
          if (timesheet) {
            timesheet.laborOrder = response.laborOrder;
            ctx.patchState({ selectedItem: JSON.stringify(timesheet) });
          }
        }
      },
      (error) => {
        callbackError(error);
      }
    )
  }

  @Action(UpdateCurrentGang)
  updateCurrentGang(ctx: StateContext<TimeheetsStateModel>, action: UpdateCurrentGang) {
    ctx.patchState({
      currentGang: action.gang
    });
  }

  @Action(LoadTimekeepers)
  loadTimekeepers(ctx: StateContext<TimeheetsStateModel>, action: LoadTimekeepers) {
    return this.TimesheetsService.getTimekeepers().toPromise().then(
      (response) => {
        ctx.patchState({
          timeKeepers: response.map(e => {
            return {
              value: e.id,
              text: e.userName
            }
          }) 
        })
      });
  }
}
